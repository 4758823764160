import React, { FunctionComponent, useState, ReactNode } from 'react';
import { animateScroll } from 'react-scroll';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../shared/utils/scrolling';
import Contact from '../shared/components/contact';
import './component.scss';

type LayoutProps = {
    children?: ReactNode
};

const Layout: FunctionComponent<LayoutProps> = ({
    children
}) => {
    const [navExpanded, setNavExpanded] = useState<boolean>(false);

    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top" expanded={navExpanded} onToggle={(expanded: boolean) => setNavExpanded(expanded)}>
                <div id="navbar-brand-and-toggle">
                    <Navbar.Brand as={Link} to="/#home" onClick={() => animateScroll.scrollToTop()}>{<img src="logo.svg" alt="logo" />}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <NavHashLink 
                            activeClassName="active"
                            to="/#home"
                            isActive={(match, location) => location.pathname === '/' && (!location.hash || location.hash === '#home')}
                            scroll={el => scrollWithOffset(el, -80)}
                            onClick={() => setNavExpanded(false)}
                        >
                            Home
                        </NavHashLink>
                        <NavHashLink
                            activeClassName="active"
                            to="/#about"
                            isActive={(match, location) => location.pathname === '/' && location.hash === '#about'}
                            scroll={el => scrollWithOffset(el, -80)}
                            onClick={() => setNavExpanded(false)}
                        >
                            About
                        </NavHashLink>
                        <NavHashLink 
                            activeClassName="active"
                            to="/#services"
                            isActive={(match, location) => location.pathname === '/' && location.hash === '#services'}
                            scroll={el => scrollWithOffset(el, -80)}
                            onClick={() => setNavExpanded(false)}
                        >
                            Services
                        </NavHashLink>
                        {/* <NavLink
                            to="/projects"
                            activeClassName="active"
                            onClick={() => setNavExpanded(false)}
                        >
                            Projects
                        </NavLink> */}
                        <NavLink
                            to="/contact"
                            activeClassName="active"
                            onClick={() => setNavExpanded(false)}
                        >
                            Contact
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div className="container-fluid page">
                {children}

                <div className="footer">
                    <Contact />
                </div>
            </div>
        </>
    );
};

export default Layout;
