import React, { FunctionComponent } from 'react';
import MetaTags from 'react-meta-tags';
import Service from './services/service';
import ScrollTop from './scroll-top';
import { services } from './services/services';
import './component.scss';

const Home: FunctionComponent = () => (
    <>
        <MetaTags>
            <title>Steadfast Projects</title>
            <meta name="description" content="Property renovations and maintenance including electrical, plumbing, carpentry, handyman services, painting and minor building works in and around Cape Town." />
        </MetaTags>

        <div id="home">
            <div className="image-text-container">
                <h1 id="brand">
                    We perform home and commercial property renovations & maintenance.
                </h1>
            </div>
        </div>

        <div id="about">
            <p>At Steadfast we do not view your project as a daunting task but rather as the start of a new relationship. We pride ourselves in living up to our name by providing a loyal, reliable and honest service, in turn building lasting relationships with you as our valued customer.</p>
            <p>With over 19 years of experience in the building construction and maintenance industry, we are extremely confident in the professional advice and service we are able to offer you.</p>
            <p>We are based in Bloubergstrand, Cape Town, Western Cape and cover all areas between Sunset Beach and Melkbosstrand.</p>
        </div>

        <div id="services">
            <div className="image-text-container">
                <div id="services-container">
                    {services.map(s => (
                        <Service
                            key={s.name}
                            name={s.name}
                            description={s.description}
                            iconClass={s.iconClass}
                        />
                    ))}
                </div>
            </div>
        </div>

        <ScrollTop />
    </>
);

export default Home;
