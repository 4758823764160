import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import Layout from './layout/component';
import HomePage from './home/component';
import ContactPage from './contact/component';

const App = () => (
    <BrowserRouter>
        <Layout>
            <Switch>
                <Route exact path="/"><HomePage /></Route>
                <Route exact path="/contact"><ContactPage /></Route>
            </Switch>
        </Layout>
    </BrowserRouter>
);

export default App;
