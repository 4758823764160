import { faLightbulb, faToilet, faHammer, faTools, faPaintRoller, faHardHat } from '@fortawesome/free-solid-svg-icons';

const services = [
    { name: 'Electrical', description: 'Registered and licensed electrical service offering industrial, commercial and domestic maintenance and installations. We can also assist with energy efficient and backup power solutions.', iconClass: faLightbulb },
    // { name: 'Plumbing', description: 'We can assist with all your domestic plumbing needs, from procurement and installation of all sanitary ware to the maintenance of your existing installation.', iconClass: faToilet },
    // { name: 'Carpentry', description: 'We offer bespoke kitchens and built-in cupboards tailor made to your specific requirements as well as custom-made furniture. We also specialise in custom timber decking and pergolas as well as all your timber maintenance and repairs.', iconClass: faHammer },
    { name: 'Handyman', description: 'Let us assist with your ever growing to-do list around the house and office. We offer full day or hourly assistance with all your minor plumbing, painting, carpentry and just about any hands-on job around your house and office.', iconClass: faTools },
    // { name: 'Painting', description: 'Looking for a long lasting quality finish on your interior or exterior painting of your home or office space? Give us a call as we believe that the key in a high quality finish is all in the preparation and workmanship. We can also assist in feature walls and decorative painting. It’s not JUST painting.', iconClass: faPaintRoller }
    // { name: 'Minor Building Works', description: 'Contact us if you are wanting to carry out any minor building works on your home or office. We can assist with drawing and submissions of your plans to the local council and once approved we will bring your dream to reality.', iconClass: faHardHat }
];

export {
    services
};