import React, { FunctionComponent, useState } from 'react';
import {
    Formik,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import { API } from 'aws-amplify'
import { validate } from './sync-validate';
import { ContactFormValues } from './types';

const ContactForm: FunctionComponent = () => {
    const [submittedSuccessfully, setSubmittedSuccessfully] = useState<null | boolean>(null);
    
    const initialValues: ContactFormValues = {
        name: '',
        email: '',
        contactNumber: '',
        message: ''
    };
    
    async function sendMessage(values: ContactFormValues) {
        try {
            await API.post('steadfastprojectscontactapi', '/contact', { body: values });
            setSubmittedSuccessfully(true);
        } catch (e) {
            setSubmittedSuccessfully(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={async (values: ContactFormValues) => sendMessage(values)}
            render={(formikBag: FormikProps<ContactFormValues>) => (
                <Form>
                    <Field
                        name="name"
                        render={({ field, meta } : FieldProps) => (
                            <div className="form-group">
                                <input type="text" className="form-control" {...field} placeholder="Name" />
                                <span className="form-validation">{meta.touched && meta.error}</span>
                            </div>
                        )}
                    />
                    <Field
                        name="contactNumber"
                        render={({ field, meta } : FieldProps) => (
                            <div className="form-group">
                                <input type="text" className="form-control" {...field} placeholder="Contact number" />
                                <span className="form-validation">{meta.touched && meta.error}</span>
                            </div>
                        )}
                    />
                    <Field
                        name="email"
                        render={({ field, meta } : FieldProps) => (
                            <div className="form-group">
                                <input type="email" className="form-control" {...field} placeholder="Email" />
                                <span className="form-validation">{meta.touched && meta.error}</span>
                            </div>
                        )}
                    />
                    <Field
                        name="message"
                        render={({ field, meta } : FieldProps) => (
                            <div className="form-group">
                                <textarea className="form-control" rows={5} {...field} placeholder="How can we help you?" />
                                <span className="form-validation">{meta.touched && meta.error}</span>
                            </div>
                        )}
                    />

                    <button type="submit" className="btn btn-primary" disabled={formikBag.isSubmitting} onClick={() => setSubmittedSuccessfully(null)}>
                        Send message
                    </button>

                    {submittedSuccessfully !== null && (
                        submittedSuccessfully ? <span id="contact-success">Message sent successfully!</span> : <span id="contact-failure">Failed to send message.</span>
                    )}
                </Form>
            )}
        />
    );
};

export default ContactForm;
