import React, { FunctionComponent } from 'react';
import MetaTags from 'react-meta-tags';
import ContactForm from './form/form';
import ContactDetails from '../shared/components/contact';
import './component.scss';

const Contact: FunctionComponent = () => (
    <div id="contact">
        <MetaTags>
            <title>Steadfast Projects - Contact</title>
            <meta name="description" content="We look forward to hearing from you. Contact us directly or use the below form to leave a message." />
        </MetaTags>
        
        <div className="row">
            <div className="col col-0 col-lg-1"></div>
            <div className="col col-12 col-lg-4">
                <h1 id="contact-header">We look forward to hearing from you!</h1>
            </div>
            <div className="col col-0 col-lg-1"></div>
            <div className="col col-12 col-lg-5">
                <div id="contact-details" className="image-text-container">
                    <ContactDetails />
                </div>
                <br />

                <span id="contact-form-header">Send us a message</span>
                <ContactForm />
            </div>
        </div>
    </div>
);

export default Contact;
