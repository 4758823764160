import React, { FunctionComponent } from 'react';
import { animateScroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './scroll-top.scss';

const ScrollTop: FunctionComponent = () => (
    <div
        id="scroll-top"
        onClick={() => animateScroll.scrollToTop()}
    >
        <FontAwesomeIcon icon={faChevronUp} />
    </div>
);

export default ScrollTop;
