import { ContactFormValues } from './types';
import { requiredMessage } from '../../shared/utils/validation';

export const validate = (values: ContactFormValues) => {
    const errors: Partial<ContactFormValues> = {};

    if (!values.name) {
        errors.name = requiredMessage('Name');
    }

    if (!values.contactNumber) {
        errors.contactNumber = requiredMessage('Contact number');
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email is not valid.';
    }

    if (!values.message) {
        errors.message = requiredMessage('Message');
    }

    return errors;
};