import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './service.scss';

type ServiceProps = {
    name: string,
    description: string,
    iconClass: IconProp
};

const Service: FunctionComponent<ServiceProps> = ({
    name,
    description,
    iconClass
}) => (
    <div className="service">
        <div>
            <FontAwesomeIcon icon={iconClass} />
            <h3 className="service-name">{name}</h3>
        </div>
        <div className="service-description">{description}</div>
    </div>
);

export default Service;
