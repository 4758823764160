import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { contactNumber, emailAddress } from '../constants/contact';

const Contact: FunctionComponent = () => (
    <>
        <div>
            <a href={`mailto:${emailAddress}`} className="no-link">
                <FontAwesomeIcon icon={faEnvelope} /><span>{emailAddress}</span>
            </a>
        </div>
        <div>
            <a href={`tel:${contactNumber}`} className="no-link">
                <FontAwesomeIcon icon={faPhone} /><span>{contactNumber}</span>
            </a>
        </div>
    </>
);

export default Contact;
